
export const About = () => {
    return (
        <section id="about" className="resume">
            <div className="container">
                <div className="section-title">
                    <h2>About me</h2>
                    <p> Hello there, I'm <strong>Axel Clerget</strong> aka <strong><em>Maxwell</em></strong> and I am a <strong>Technical designer</strong>.</p>
                    <p>
                        Passionate about level and game design aspects, you will find most of the game projects,
                        tools and prototypes that I worked on.
                    </p>
                    {/*
                    <br/>
                    <p>
                        Want to know more about my professional experience and my studies? <a href="#resume" className="btn btn-primary btn-sm">More info</a>.
                    </p>
                    */}
                </div>
            </div>
        </section>
    )
}