import {PortfolioProject} from "./components/PortfolioProject";
import {Room404Page} from "../../projects/room404/Room404Page";
import {CompilationPage} from "../../projects/compilation/CompilationPage";
import {KarnagPage} from "../../projects/karnag/KarnagPage";
import {UnrealFPVPage} from "../../projects/unrealFPVPage/UnrealFPVPage";
import {UmbraFrameworkPage} from "../../projects/umbraFramework/UmbraFrameworkPage";
import {UmbraDialoguePage} from "../../projects/umbraDialogue/UmbraDialoguePage";
import {FeynPage} from "../../projects/feyn/FeynPage";
import {SeraphChroniclePage} from "../../projects/seraphChronicle/SeraphChroniclePage";
import {PortalPuzzlePage} from "../../projects/portalPuzzle/PortalPuzzlePage";
import {Badge} from "react-bootstrap";

export const Portfolio = () => {
  const GDTag = <Badge pill bg="primary">Game design</Badge>
  const LDTag = <Badge pill bg="success">Level design</Badge>
  const PTag = <Badge pill bg="danger">Programming</Badge>

  return (
      <>
        <section id="portfolio" className="portfolio section-bg">
          <div className="container">

            <div className="section-title">
              <h2>Portfolio</h2>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">All</li>
                  <li data-filter=".filter-games">Games</li>
                  <li data-filter=".filter-tools">Tools</li>
                  {/*<li data-filter=".filter-prototype">Prototypes</li>*/}
                  <li data-filter=".filter-student">Student</li>
                  <li data-filter=".filter-gd">Game design</li>
                  <li data-filter=".filter-ld">Level design</li>
                  <li data-filter=".filter-prog">Programming</li>
                </ul>
              </div>
            </div>

            <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">
              <PortfolioProject title="BlitzBot" img="blitzbot.jpg" filters={['games', 'gd', 'ld', 'prog']} slug="blitzbot" link={"https://www.revolt-studios.com/#games"} gameTags={[GDTag, LDTag, PTag]} />
              <PortfolioProject title="Room 404" img="room404.jpg" filters={['games',  'gd', 'ld', 'prog']} slug="room-404" modalContent={<Room404Page/>} gameTags={[GDTag, LDTag, PTag]} />
              <PortfolioProject title="Seraph's chronicle" img="seraph_chronicle.jpg" filters={['games',  'gd', 'ld', 'prog']} slug="seraph-chronicle" modalContent={<SeraphChroniclePage />} gameTags={[GDTag, LDTag, PTag]} />
              <PortfolioProject title="Portal 2 - Puzzle game" img="portal_puzzle.jpg" filters={['games', 'ld']} slug="portal-puzzle" modalContent={<PortalPuzzlePage/>} gameTags={[LDTag]} />
              <PortfolioProject title="Feyn" img="feyn.jpg" filters={['games',  'gd', 'ld', 'prog']} slug="feyn" modalContent={<FeynPage />} gameTags={[GDTag, LDTag, PTag]} />
              <PortfolioProject title="Unreal-FPV" img="ufpv.jpg" filters={['games', 'gd', 'prog']} slug="unreal-FPV" modalContent={<UnrealFPVPage />} gameTags={[GDTag, PTag]} />
              <PortfolioProject title="Compilation" img="compilation.jpg" filters={['games', 'gd', 'ld', 'prog']} slug="compilation" modalContent={<CompilationPage />} gameTags={[GDTag, LDTag, PTag]} />
              {/*
              <PortfolioProject title="Prime" img="prime.jpg" filter="prototype" slug="prime" />
              <PortfolioProject title="Braver" img="knightsoul.jpg" filter="games" slug="compilation" modalContent={<CompilationPage/>}/>
              <PortfolioProject title="Showreel" img="showreel.jpg" filter="games" slug="showreel" />
              */}
              <PortfolioProject title="Rise of Karnag" img="karnag.jpg" filters={['student', 'ld', 'prog']} slug="rise-of-karnag" modalContent={<KarnagPage />} gameTags={[LDTag, PTag]} />
              <PortfolioProject title="Umbra Framework" img="ut_framework.jpg" filters={['tools', 'prog']} slug="umbra-framework" modalContent={<UmbraFrameworkPage />}  gameTags={[PTag]} />
              <PortfolioProject title="Umbra Dialogue" img="ut_dialogue_system.jpg" filters={['tools', 'prog']} slug="umbra-dialogue" modalContent={<UmbraDialoguePage />}  gameTags={[PTag]} />
              <PortfolioProject title="Github repositories" img="github.jpg" filters={['tools', 'prog']} slug="github" link="https://github.com/Maxwell21" gameTags={[PTag]} />
            </div>

          </div>
        </section>
      </>
  )
}