import React from "react";

export const Resume = () => {
    return (
        <>
            <section id="resume" className="resume">
                <div className="container">
                    <div className="row">
                        <div className="section-title">
                            <h2>Resume</h2>
                        </div>

                        <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
                            <h3 className="resume-title">Professional Experience</h3>
                            <div className="resume-item">
                                <h4>Game/Level/Technical Designer & Producer</h4>
                                <h5>2023 - Present</h5>
                                <p><em>Revolt, Lyon, France</em></p>
                                <ul>
                                    <li>Working on indie games.</li>
                                    <li>Helping sole or studios by purposing services on game, level and technical design as well as producing.</li>
                                    <li>In charge of helping game designers to achieve their vision or otherwise
                                        designing
                                        and prototyping game mechanics.
                                    </li>
                                    <li>In charge of helping level designers to achieve their vision or otherwise
                                        participating
                                        in the conception of the level's layout, flow and level's intention.
                                    </li>
                                    <li>In charge of writing the design and technical documentation for both programmers
                                        and designers.
                                    </li>
                                    <li>Making Unreal engine blueprint or C++ tools in order to improve the designers'
                                        workflow.
                                    </li>
                                    <li>Responsible for the engine guidelines (naming conventions,
                                        structure conventions) and automation pipeline (testing, build).
                                    </li>
                                    <li>Responsible for implementing complete game features (design and programming).
                                    </li>
                                    <li>Responsible for making functional tests in engine of the game features.</li>
                                    <li>Using Unreal Engine 4 and Unreal Engine 5.</li>
                                </ul>
                            </div>
                            <div className="resume-item">
                                <h4>Producer</h4>
                                <h5>2018 - Present</h5>
                                <p><em>TMDS, Lyon, France</em></p>
                                <ul>
                                    <li>Planning milestones and objectives to achieve, daily management of production
                                        teams in an agile environment.
                                    </li>
                                    <li>Ensures the quality and tracking of deliverables, their presentation, and
                                        incorporation of feedback.
                                    </li>
                                    <li>Management and budget monitoring of overall production.</li>
                                    <li>Management of external outsourced services.</li>
                                </ul>
                            </div>
                            <div className="resume-item">
                                <h4>R&D Developer</h4>
                                <h5>2015 - 2018</h5>
                                <p><em>Genedys, Lyon, France</em></p>
                                <ul>
                                    <li>Creation of "Micser", a new framework based on microservices and middleware
                                        technologies.
                                    </li>
                                    <li>Tests, profiling and optimization.</li>
                                    <li>Programming languages and technologies used: C++, Qt, C#, React, Javascript...
                                    </li>
                                </ul>
                            </div>
                            <div className="resume-item">
                                <h4>Mobile developer</h4>
                                <h5>2015 - 3 months</h5>
                                <p><em>Effet-B, Villeurbanne, France</em></p>
                                <ul>
                                    <li>Developed iOs/android mobile applications.</li>
                                </ul>
                            </div>
                            <div className="resume-item">
                                <h4>Web developer</h4>
                                <h5>2014 - 2015</h5>
                                <p><em>Dedi Agency, Lyon, France</em></p>
                                <ul>
                                    <li>Research and creation of "FeedBii", a "business intelligence" platform.</li>
                                    <li>Improving internal tools and systems.</li>
                                    <li>Programming languages used: C++, NoSQL, Symfony2, Hadoop, Javascript,
                                        Typescript
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-lg-12" data-aos="fade-up">
                            <h3 className="resume-title">Education</h3>
                            <div className="resume-item">
                                <h4>Computer science bachelor's degree</h4>
                                <p><em>EPSI - École privée des sciences informatiques, Lyon, France.</em></p>
                            </div>
                            <div className="resume-item">
                                <h4>BTS Services informatiques aux organisations</h4>
                                <p><em>Lycée Pasteur Mont Roland, Dole, France.</em></p>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-lg-12" data-aos="fade-up">
                            <h3 className="resume-title">Languages</h3>
                            <div className="resume-item">
                                <h4>French - Native speaker</h4>
                                <h4>English - B2</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>
                <div className="container services">
                    <div className="row">
                        <div className="section-title">
                            <h2>Tools/Software/Programming</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                            <img className="icon" src="https://img.icons8.com/windows/512/unreal-engine.png"
                                 alt="unreal engine"/>
                            <h4 className="title">Unreal engine 4 / Unreal Engine 5</h4>
                        </div>
                        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                            <img className="icon" src="https://cdn-icons-png.flaticon.com/512/5969/5969294.png"
                                 alt="unity"/>
                            <h4 className="title">Unity</h4>
                        </div>
                        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                            <img className="icon"
                                 src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Blender_logo_no_text.svg/939px-Blender_logo_no_text.svg.png"
                                 alt="blender"/>
                            <h4 className="title">Blender</h4>
                        </div>
                        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                            <img className="icon"
                                 src="https://cdn.icon-icons.com/icons2/3914/PNG/512/perforce_logo_icon_248875.png"
                                 alt="perforce"/>
                            <h4 className="title">Perforce</h4>
                        </div>
                        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                            <img className="icon" src="https://cdn-icons-png.flaticon.com/512/6132/6132222.png"
                                 alt="c++"/>
                            <h4 className="title">C++</h4>
                        </div>
                        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                            <img className="icon"
                                 src="https://static-00.iconduck.com/assets.00/c-sharp-c-icon-456x512-9sej0lrz.png"
                                 alt="c#"/>
                            <h4 className="title">C#</h4>
                        </div>
                        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                            <img className="icon"
                                 src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/2101px-Adobe_Photoshop_CC_icon.svg.png"
                                 alt="photoshop"/>
                            <h4 className="title">Photoshop</h4>
                        </div>
                        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                            <img className="icon"
                                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQs9h9nF_S8Nfw_VS02ZXOUSKxfgHDXsIxbNxt8IPKWrw&s"
                                 alt="Miro"/>
                            <h4 className="title">Miro</h4>
                        </div>
                        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                            <img className="icon"
                                 src="https://cdn.icon-icons.com/icons2/1156/PNG/512/1486565573-microsoft-office_81557.png"
                                 alt="office suite"/>
                            <h4 className="title">Office Suite (Word, Excel, Powerpoint...)</h4>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}